$timeframe-border-color: #dcdcdc!default;
$timeframe-active-color: #27aae2!default;
$frame-transition-duration: 0.24s!default;
$frame-height: 9px!default;
$frame-info-height: 35px!default;
$timeframe-height: $frame-height + $frame-info-height!default;
$frame-info-color: #838687!default;
$frame-active-info-color: #000!default;
$frame-bg-color: #fff!default;


$clock-size: 22px!default;
$clock-transition-duration: 0.3s!default;
$clock-transition-style: cubic-bezier(0.680, -0.550, 0.265, 1.550)!default; //easeInOutBack
$clock-disabled-bg: #ededed;
$clock-disabled-color: #7B7676;




.clock {
	width: 22px;
	height: 22px;
	position: absolute;
	top: -6.5px;
	border: 1px solid #27aae2;
	border-radius: 12px;
	z-index: 5;
	background: white;
	cursor: pointer;
	transition-duration: 0.3s;
	-webkit-transition: -webkit-all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	transition: -webkit-all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	-o-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55),
		-webkit-all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.frame-dot {
		display: inline-block;
		position: absolute;
		width: 3px;
		height: 3px;
		top: 3px;
		left: 0;
		right: 0;
		margin: 0 auto;
		background-color: #838687;
		border-radius: 100%;
	}
	.frame-input {
		display: none !important;
	}
	.time8-10 {
		left: 9%;
	}
	.time8-10 .dial2 {
		transform: rotate(240deg);
	}
	.time9-11 {
		left: 34%;
	}
	.time9-11 .dial2 {
		transform: rotate(270deg);
	}
	.time1-3 {
		left: 59%;
	}
	.time1-3 .dial2 {
		transform: rotate(30deg);
	}
	.time2-4 {
		left: 84%;
	}
	.time2-4 .dial2 {
		transform: rotate(60deg);
	}
	.dial {
		background-color: #f7801e;
		width: 2px;
		height: 6px;
		position: absolute;
		top: 5px;
		left: 50%;
		margin: auto -1px;
		-ms-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
		-webkit-transform-origin: 50% 100%;
		-moz-transform-origin: 50% 100%;
		-o-transform-origin: 50% 100%;
	}
	.clock.disabled {
		border-color: #7B7676;
		background-color: #ededed;
	}
	.clock.disabled > .dial {
		background-color: #7B7676;
	}
	.frame {
		position: relative;
		display: inline-block;
		cursor: pointer;
		text-align: center;
		width: 25%;
		background-color: inherit !important;
	}
	.time-box {
		display: block;
		height: 35px;
		line-height: 35px;
		font-size: 10px;
		color: #838687;
		background-color: inherit !important;
	}
	@media only screen and (max-width: 991px) {
		.time-box {
			font-size: 16px !important;
		}
	}
	.frame:hover:before,
	.frame.active:before {
		background-color: #f7801e;
	}
	.frame.disabled:hover:before {
		background-color: #fff;
	}
	.frame.disabled.active:before {
		background-color: #838687;
	}
	.frame:before {
		content: '';
		display: block;
		height: 9px;
		position: relative;
		z-index: 0;
		background: #fff;
		-webkit-transition: all 0.24s ease-in;
		-o-transition: all 0.24s ease-in;
		transition: all 0.24s ease-in;
		border: solid #dcdcdc;
		border-width: 1px 1px 1px 0;
	}
	.frame.first:before {
		border-left-width: 1px;
	}
	.timeframe {
		display: flex;
		flex-direction: row;
		width: 240px;
		position: relative;
		top: 6px;
	}

