$black: #000000;
$red: #D63232;
$blue: #0073bb;
$green: #41a700;
$text-dark-gray: #3F3F3F;
$text-gray: #848484;
$text-light-gray: #B9BCBF;
$red-light: rgba(203, 0, 5, 0.15);

$panel-border-color: #d6d6d6;

$input-border-color: #d6d6d6;


// gray cta button
$grayBtnTxt: #052438;
$grayBtnTxtShadow: #f6f6f6;
$grayBtnBorder: #ababab;
$grayBtnRegularStart: #ffffff;
$grayBtnRegularEnd: #e1e1e1;
$grayBtnHoverStart: #ffffff;
$grayBtnHoverEnd: #ededed;
$grayBtnDownStart: #d2d2d2;
$grayBtnDownEnd: #f5f5f5;
$hpGray: #595959;
$placeHolderGray: #969696;

// orange
$orange: #f7801e;
$orangeSuperLight: #fdf3ea;
$orangePress: #f47200;
$orangeBorderDark: #bc5300;
$orangeBtnTxtShadow: #e67200;
$orangeBtnRegularEnd: #f17000;
$orangeBtnRegularStart: #f59400 ;
$orangeBtnHoverStart: #f59c00 ;
$orangeBtnHoverEnd: #f37f00;
$orangeBtnDownStart: #f27000;
$orangeBtnDownEnd: #f38200;
$newButtonOrange: #f18222;

// grays
$lightgray: #f7f7f7;
$normalgray: #f2f2f2;
$pingray: #91a4aa;
$borderGray: #d6d6d6;
$borderGrayHover: #89959b;
$lightBorderGray: #dddddd;
$darkBorderGray: #bababa;
$textGray: #cacaca;
$disabledGray: #838687;
$textGrayDark: #737677;
$bgGray: #f6f6f6;
$darkBgGray: #e3e4e6;
$menu-border-dark: rgba(0, 0, 0, 0.17);
$menu-border-light: rgba(255, 255, 255, 0.06);
$grandTotalGray: #eff1f3;

// blues
$darkblue: #042b3b;
$darkblue2: #003854;
$linkblue: #337a8c;
$white: #ffffff;
$cyanHover: #2bbdec;
$cyan: #00bee8;
$turquoiseHover: #28afe8;
$darkblueborder: #829ca1;
$date-picker-active: #41b8eb;
