.bg-primary {
	background-color: $orange !important;
}
.bg-secondary {
	background-color: $darkblue !important;
}
.f-c-primary {
	color: $orange !important;
}
.f-c-secondary {
	color: $darkblue !important;
}
.f-c-cyan {
	color: $cyan !important;
}
.f-c-green {
	color: $green !important;
}
.btn {
  border-radius: 0;
}
.btn-primary {
	background-color: $orange !important;
	border-color: $orange !important;
  color: white !important;
  display: inline-block;
  width: auto;
  white-space: nowrap;
  vertical-align: top;
  border-width: 1px!important;
  border-top-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;
  border-style: solid!important;
  border-top-style: solid !important;
  border-right-style: solid !important;
  border-bottom-style: solid !important;
  border-left-style: solid !important;
}
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
	background-color: $orange !important;
	border-color: $orange !important;
}
.btn-secondary {
	background-color: #003854 !important;

	border-color: black;
	color: white;
	border-style: solid;
}

.btn-secondary:hover,
.btn-secondary:active {
  box-shadow: none;
}
.btn-secondary:active
// .btn-secondary:focus
 {
	background-color: $orange !important;
	border-color: $orange !important;
	color: $darkblue !important;
	box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1) !important;
}
.inventory-edit-view .tabs-stretch .tab-active {
	border-left-color: $orange !important;
	border-right-color: $orange !important;
	border-bottom-color: transparent !important;

}
.inventory-edit-view .tabs-wrapper .tab-active a {
	color: $orange !important;
}

.progress-bar .step-active .step-link {
	color: $orange !important;
}
.progress-bar .step-active {
  fill: $orange !important;
}
.progress-bar .switch.checked {
	background-color: $orange !important;
	border-color: $orange !important;
}
.inventory-overview-view svg {
  max-height: 100px;
}
.tab-active {
	color: $orange !important;
}
.tab-icon.tab-active svg {
	fill: $orange !important;
}
.tab-icon.tab-active span {
	color: $orange !important;
}
.step-link svg {
  height: 18px;
  width: 18px;
  margin-right: 0.35rem;
}

.timeframe .clock .dial,
.timeframe .frame.active .frame-dot,
.timeframe .frame.active:before,
.clock .dial {
	background-color: $orange !important;
}

.timeframe .clock {
	border-color: $orange !important;
}
.timeframe .frame.active:after {
	color: $orange !important;
}
.clock {
	border-color: $orange !important;
}

.sign-in-tabs > a:hover {
	color: $orange !important;
}

.select-dropdown .dropdown-item span:hover:before,
.select-dropdown .dropdown-item span:focus:before {
	background-color: $orange !important;
}

.select-dropdown {

  svg {
    position: absolute;
    height: 62px;
    width: 32px;
    margin: 0 0 0 5px;
    padding-bottom: 6px;
    z-index: 5;
  }
}

.date-picker {
  position: absolute
}
.abs-position {
	position: absolute;
	top: 160px;
	width: 93%;
	display: flex;
	justify-content: center;
	button {
		flex-basis: 50%;
	}
}