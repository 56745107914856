@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + '.eot');
    src: url($file-name + '.eot?#iefix')  format('embedded-opentype'),
         url($file-name + '.woff') format('woff'),
         url($file-name + '.ttf')  format('truetype'),
         url($file-name + '.svg##{$font-name}')  format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

// convert px to em
@function em($pixels, $context: 16) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }
  @if (unitless($context)) {
    $context: $context * 1px;
  }
  @return $pixels / $context * 1em;
}

@include font-face('HelveticaNeue', '/fonts/helvetica-neue/helvetica-neue-regular', 400, normal);
@include font-face('HelveticaNeue', '/fonts/helvetica-neue/helvetica-neue-bold', 700, normal);

@include font-face('ProximaNova', '/fonts/proxima-nova/proxima-nova-light', 200, normal);
@include font-face('ProximaNova', '/fonts/proxima-nova/proxima-nova-regular', 400, normal);
@include font-face('ProximaNova', '/fonts/proxima-nova/proxima-nova-semibold', 600, normal);
@include font-face('ProximaNova', '/fonts/proxima-nova/proxima-nova-bold', 700, normal);


@import "../variables/core";
.text-gray {
	color: #848484;
}
.text-red {
	color: $red !important;
}
.text-green {
	color: $green !important;
}
.text-compact {
	letter-spacing: -0.025em;
}
.valign-top {
	vertical-align: top;
}

.l-h-1 {
	line-height: 1;
}

.bg-gray {
	background-color: #f5f5f5 !important;
}

$fontsizes: (12, 13, 14, 16, 18, 20, 22, 24, 26, 32);

@each $size in $fontsizes {
	.f-s-xs-#{$size} {
		font-size: #{$size}px !important;
	}
	@media only screen and (min-width: 768px) {
		.f-s-sm-#{$size} {
			font-size: #{$size}px !important;
		}
	}
	@media only screen and (min-width: 992px) {
		.f-s-md-#{$size} {
			font-size: #{$size}px !important;
		}
	}
	@media only screen and (min-width: 1200px) {
		.f-s-lg-#{$size} {
			font-size: #{$size}px !important;
		}
	}
}

$fontweights: (200, 400, 600, 700);

@each $weight in $fontweights {
	.f-w-#{$weight} {
		font-weight: #{$weight} !important;
	}
}
