.replaceify {
  position: relative;
  svg {
    position: absolute;
    height: auto;
    left: 4px;
  }
  .icon.from-arrow svg {
    fill: #042b3b;
  }
  .icon.to-arrow svg {
    fill: #042b3b;
    height: 10px;
  }
  .icon.blank-pin {
    fill: #91a4aa;
    vertical-align: middle;
    margin-right: 4px;
    -webkit-transition: color 0.25s;
    -o-transition: color 0.25s;
    transition: color 0.25s;
    width: 12px;
  }
}

.address-autocomplete {
  position: relative;
  .icon {
    position: absolute;
    width: 32px;
    height: 64px;
    left: 5px;
    bottom: 2px;
    font-size: em(22px);
    color: #91a4aa;
  }

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
  }

	.predictions-dropdown {
		position: absolute;
		z-index: 100;
		background: #fff;
		width: 100%;
		border: solid #e9e9e9;
		border-width: 0 1px;
		border-radius: 0 0 2px 2px;
		transition: opacity 0.2s ease-in;
		cursor: pointer;
		width: inherit;

		.prediction-item {
			position: relative;
			transition: background .2s ease-in-out;
			padding: 8px;
			font-size: em(13px);
			line-height: 18px;
			border-bottom: 1px solid #e9e9e9;
			text-decoration: none;

			&:before {
				color: #91a4aa;
				vertical-align: middle;
				margin-right: 4px;
				transition: color 0.25s;
			}

			&:after {
				content: "";
				display: block;
				width: 3px;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background-color: transparent;
				text-transform: none;
				transition: background-color 0.25s;
			}

			&:hover,
			&.selected,
			&.hovered {
				background-color: #e2e3e5;

				&:before {
					color: #f7801e;
				}

				&:after {
					background-color: #f7801e;
				}
			}
		}
	}
}
.replaceify {
  position: relative;
  svg {
  position: absolute;
  width: 32px;
  height: 44px;
  left: 4px;
  }
  .icon.from-arrow svg {
    fill: #042b3b;
  }
  .icon.to-arrow svg {
    fill: #042b3b;
    height: 10px;
  }
  .icon.blank-pin {
    fill: #91a4aa;
    vertical-align: middle;
    margin-right: 4px;
    -webkit-transition: color 0.25s;
    -o-transition: color 0.25s;
    transition: color 0.25s;
    width: 12px;
  }
}

.geosuggest__suggests {
  position: relative;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0 !important;
  padding: 0 !important;
}

.suggestions-dropdown {
  position: absolute;
  width: calc(100% - 15px);
  z-index: 5;
  background: #fff;
  border: solid #829ca1;
  border-width: 0 1px 1px;
  border-radius: 0 0 2px 2px;
  -webkit-transition: opacity .2s ease-in;
  -o-transition: opacity .2s ease-in;
  transition: opacity .2s ease-in;
  cursor: pointer;
  margin-bottom: 0;
  padding: 0;
}

.suggestions-list {
  list-style: none !important;
  margin: 0;
}

.suggestions-item {
  position: relative;
  -webkit-transition: background .2s ease-in-out;
  -o-transition: background .2s ease-in-out;
  transition: background .2s ease-in-out;
  padding: 8px;
  font-size: 13px;
  line-height: 18px;
  border-bottom: 1px solid #e9e9e9;
  text-decoration: none;
  list-style: none;
}

.icon.blank-pin {
  fill: #91a4aa;
  vertical-align: middle;
  margin-right: 4px;
  -webkit-transition: color .25s;
  -o-transition: color .25s;
  transition: color .25s;
  width: 12px;
}

.suggestions-item:hover svg {
  fill: #f7801e;
}

.geosuggest__item--active {
  background-color: #e2e3e5;
}

.geosuggest__item--active::after {
  filter: brightness(4) hue-rotate(190deg) saturate(.9);
}

.geosuggest__item--active::before {
  background-color: #f7801e;
}

.geosuggest__item--active svg {
  fill: #f7801e;
}

.geosuggest ul::after {
  display: block;
  // content: url(/assets/images/powered_by_google_on_white.png);
  position: absolute;
  left: -1px;
  width: calc(100% + 2px);
  height: 27px;
  text-align: end;
  padding: 4px 10px;
  background: white;
  border: 1px solid #829ca1;
  border-top: none;
}
